import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@nuis/common';
import { map, Observable } from 'rxjs';
import { AuditModel } from '../../audits';
import { AuditsDto, mapAuditsDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class AuditsApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public getAuditLogs(entityId: string, entityTypes: string[]): Observable<AuditModel[]> {
        return this.http
            .get<AuditsDto>(`${this.config.baseUrl}Audit/GetAuditLogs`, {
                params: {
                    entityId: entityId,
                    entityTypes: entityTypes,
                },
            })
            .pipe(map(mapAuditsDto));
    }
}
