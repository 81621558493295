import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, QueryRequestDto, QueryResultDto, ReferenceDto } from '@nuis/common';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import {
    EmailDto,
    mapEmailDto,
    mapNotificationDto,
    mapSmsDto,
    NotificationDetailsDto,
    NotificationDto,
    NotificationFilterDto,
    NotificationQueryResultDto,
    SmsDto,
} from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public getNotifications(
        options: QueryRequestDto<NotificationFilterDto>,
    ): Observable<QueryResultDto<NotificationDto>> {
        return this.http
            .post<
                NotificationQueryResultDto<NotificationDto & { timestamp: string; emailId: string | null }>
            >(`${this.config.baseUrl}Notifications/QueryList`, options)
            .pipe(
                map((response) => {
                    return {
                        records: response.notifications.map(mapNotificationDto),
                        totalRecords: response.totalRecords,
                    };
                }),
            );
    }

    public async getNotificationsByReference(reference: ReferenceDto) {
        const response = await firstValueFrom(
            this.http.get<(NotificationDto & { timestamp: string; emailId: string | null })[]>(
                `${this.config.baseUrl}Notifications/GetNotifications`,
                { params: { ...reference } },
            ),
        );

        return response.map(mapNotificationDto);
    }

    public getNotificationDetails(
        id: string,
    ): Observable<{ notification: NotificationDetailsDto; email: EmailDto | null; sms: SmsDto | null }> {
        return this.http
            .get<NotificationDetailsDto>(`${this.config.baseUrl}Notifications/GetNotification`, {
                params: { id: id },
            })
            .pipe(
                switchMap((notification) => {
                    if (notification.messageType === 'sms' && notification.smsId != null) {
                        return this.getSms(notification.smsId).pipe(map((sms) => ({ notification, email: null, sms })));
                    } else if (notification.messageType === 'email' && notification.emailId != null) {
                        return this.getEmail(notification.emailId).pipe(
                            map((email) => ({ notification, email, sms: null })),
                        );
                    } else {
                        return of({ notification, email: null, sms: null });
                    }
                }),
            );
    }

    private getEmail(id: string): Observable<EmailDto> {
        return this.http
            .get<EmailDto>(`${this.config.baseUrl}Notifications/GetEmail`, {
                params: { emailId: id },
            })
            .pipe(map(mapEmailDto));
    }

    private getSms(id: string): Observable<SmsDto> {
        return this.http
            .get<SmsDto>(`${this.config.baseUrl}Notifications/GetSms`, {
                params: {
                    smsId: id,
                },
            })
            .pipe(map(mapSmsDto));
    }
}
